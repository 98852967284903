import type { ProductInformation } from '@orus.eu/dimensions'
import { rcProOptionalGuaranteeTitle } from '@orus.eu/dimensions'
import { productsShortNames } from '../components/pages/subscription-v2/elements/quote-page-body-element/product-information-util'
import { sendMessage } from './tracking/tracking'

type TallyPopupOptions = {
  width?: number
  layout?: 'default' | 'modal'
  hiddenFields?: {
    [key: string]: string | number | boolean | undefined | null
  }
  onSubmit?: (payload: { [key: string]: string | undefined | null }) => void
}

declare const Tally: { openPopup: (formId: string, options?: TallyPopupOptions) => void }

export const openTallyPopup = (formId: string, options?: TallyPopupOptions): void => {
  if (typeof Tally !== 'undefined' && Tally.openPopup) {
    Tally.openPopup(formId, {
      width: 500,
      ...options,
    })
    sendMessage({
      event: 'viewed_tally_form',
      form_id: formId,
    })
  } else {
    throw new Error('Tally is not available')
  }
}

export function getTallyOptions(productsInformation: ProductInformation[]): string {
  let optionsWithMrpSmallOffice = ''

  productsInformation.forEach((productInformation) => {
    optionsWithMrpSmallOffice += `- ${productsShortNames[productInformation.product]} \n`

    const isSmallOffice = productInformation.optionalGuarantees?.filter(
      (guarantee) => guarantee.type === 'mrpSmallOffice' && guarantee.isActive,
    )
    if (productInformation.product === 'rc-pro' && isSmallOffice) {
      optionsWithMrpSmallOffice += `- ${rcProOptionalGuaranteeTitle['mrpSmallOffice']} \n`
    }
  })

  return optionsWithMrpSmallOffice
}
